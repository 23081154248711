import { Spacer } from "~/widgets/spacer";
import "./generating.css";
export default function GiftCardGenerationInProgressComponent() {
  return (
    <div class="bg-baseDark flex h-full flex-col items-center justify-center text-center text-baseTertiaryLight">
      <div class={"loader"}></div>
      <Spacer height={20} />
      <span class="text-h3">
        Generating
        <br /> your gift card
      </span>
    </div>
  );
}
