import { Accessor, createSignal, JSX, onMount } from "solid-js";
import { ribbonConfetti } from "~/assets/assets";
import styles from "../gift_container.module.scss";
import { classNames } from "~/utils/etc";
import {
  BrandDetailResponse,
  VoucherDetailResponse,
} from "~/server/types/gift";
import { BorderGradient, Card } from "../gift_card";

export type GiftContainerProps = {
  brand: BrandDetailResponse;
  voucher?: VoucherDetailResponse;
  amount: number;
  isPreview?: boolean;
  description: string;
  getGiftVoucherSectionComponent?: () => JSX.Element;
  onClickUnwrapGift: () => void;
  onUnwrapAnimationComplete: () => void;
  defaultEventProps?: Record<string, any>;
};

export function GiftContainerV3(props: GiftContainerProps) {
  const [hasUnwrapLottiePlayed, setHasUnwrapLottiePlayed] = createSignal(false);
  const [animateDescription, setAnimateDescription] = createSignal(false);
  const [startInfiniteBounce, setStartInfiniteBounce] = createSignal(false);
  const [highlightCardDetails, setHighlightCardDetails] = createSignal(false);
  const [scaleDownTick, setScaleDownTick] = createSignal(false);

  onMount(() => {
    startAnimations();
  });

  async function startAnimations() {
    const ribbonConfettiElement = document.getElementById("ribbon-confetti");
    props.onClickUnwrapGift();
    await new Promise((resolve) => setTimeout(resolve, 500));
    setScaleDownTick(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setAnimateDescription(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setHasUnwrapLottiePlayed(true);
    try {
      // @ts-ignore
      ribbonConfettiElement?.play();
    } catch (e) {
      console.error(e);
    }
    await new Promise((resolve) => setTimeout(resolve, 400));
    setStartInfiniteBounce(true);
    setHighlightCardDetails(true);
    await new Promise((resolve) => setTimeout(resolve, 400));
    props.onUnwrapAnimationComplete();
  }

  return (
    <div class="relative  w-full max-w-[390px] font-['Inter'] ">
      <div
        classList={{
          [styles.giftContainerSlideUp]: hasUnwrapLottiePlayed(),
          "overflow-hidden": !hasUnwrapLottiePlayed(),
        }}
        class={classNames(
          "flex max-h-screen w-full translate-y-[100px] flex-col items-center justify-start "
        )}
      >
        <div class="relative flex w-full flex-col items-center  ">
          <div
            class="mt-[100px] transition-all duration-300 sm:mt-[200px] "
            classList={{
              "scale-100": !scaleDownTick(),
              "scale-50 ": scaleDownTick(),
            }}
          >
            <GreenSuccessTick />
          </div>
        </div>
        <DescriptionText text={props.description} />
        <div class="h-8"> &nbsp;</div>
        <AnimatedGiftCard
          brand={props.brand}
          voucher={props.voucher}
          amount={props.amount}
          isPreview={props.isPreview}
          rotateCard={hasUnwrapLottiePlayed}
          startInfiniteBounce={startInfiniteBounce}
          highlightCardDetails={highlightCardDetails}
        />
      </div>
      <div class="pointer-events-none absolute left-0 right-0 top-0 flex scale-150 ">
        <RibbonConfettiLottie />
      </div>
    </div>
  );

  function RibbonConfettiLottie() {
    return (
      <lottie-player
        id="ribbon-confetti"
        src={ribbonConfetti}
        autoplay={false}
        loop={false}
      ></lottie-player>
    );
  }

  function DescriptionText({ text }: { text: string }) {
    return (
      <div
        classList={{
          [styles.descriptionText]: animateDescription(),
          "opacity-0 flex justify-center": true,
        }}
      >
        <p
          class={classNames("max-w-[85%] text-center text-[24px] font-medium")}
        >
          {text}
        </p>
      </div>
    );
  }
}

export type AnimatedGiftCardProps = {
  brand: BrandDetailResponse;
  voucher?: VoucherDetailResponse;
  amount: number;
  isPreview?: boolean;
  getGiftVoucherSectionComponent?: () => JSX.Element;
  defaultEventProps?: Record<string, any>;
  rotateCard: Accessor<boolean>;
  startInfiniteBounce: Accessor<boolean>;
  highlightCardDetails: Accessor<boolean>;
};

export function AnimatedGiftCard(props: AnimatedGiftCardProps) {
  return (
    <div
      classList={{
        [styles.cardInfinteBounce]: props.startInfiniteBounce(),
        [styles.cardRotate]: props.rotateCard(),
      }}
      style={{
        perspective: "900px",
      }}
      class="h-[430px] w-full "
    >
      <div
        class={`flex w-full max-w-[432px] flex-col items-center justify-center px-[32px] ${styles.card}`}
      >
        <BorderGradient>
          <Card
            brand={props.brand}
            voucher={props.voucher}
            amount={props.amount}
            isPreview={props.isPreview}
            getGiftVoucherSectionComponent={
              props.getGiftVoucherSectionComponent
            }
            highlightCardDetails={props.highlightCardDetails}
            defaultEventProps={props.defaultEventProps}
          />
        </BorderGradient>
      </div>
    </div>
  );
}

function GreenSuccessTick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
    >
      <rect
        width="124"
        height="124"
        rx="62"
        fill="url(#paint0_linear_387_3189)"
      />
      <path
        d="M36.6364 64.8184L50.7273 78.9093L87.3636 42.2729"
        stroke="#EDEEF1"
        stroke-width="7.04545"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_387_3189"
          x1="62"
          y1="0"
          x2="62"
          y2="124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00CF15" />
          <stop offset="0.412448" stop-color="#00A511" />
          <stop offset="1" stop-color="#00690B" />
        </linearGradient>
      </defs>
    </svg>
  );
}
